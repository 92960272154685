jQuery(function($) {
    $( '#filter' ).submit( function() {
        var form_data = $( this ).serialize();
        var ajaxUrl = filterSubmit_ajax.ajax_url;
        $.get(
            ajaxUrl,
            //dataType: 'html',
            //type: 'GET',
            form_data,
            // data: {
            //     action: 'property_filter_handler',
            //     chosen: form_data,
            // },
            function( response ) {
                //console.log( response );
                var url = '/victoria-mls-listings-search/?' + form_data;
                var pushHistory = history.pushState( {}, '', url );
                window.dispatchEvent(new Event('popstate'));
                if ( $('.grid-view')[0] ) {
                    $( '.listings-block' ).html( response );
                };
            },
            // fail: function ( err ) {
            //     console.log( err );
            // }
        );

        return false;
    });
});