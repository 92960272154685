(function($) {

/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function new_map( $el ) {

	// var
	var $markers = $el.find('.marker');

	// vars
	var args = {
		zoom		: 12,
		//center		: new google.maps.LatLng(48.431443, -123.365574),
		mapTypeId	: google.maps.MapTypeId.ROADMAP
	};

	// create map
	var map = new google.maps.Map( $el[0], args);
	var post_positions = window.mapPositions;

	// add a markers reference
	map.markers = [];

	// get postions from JSON data
	var positions = post_positions;

	positions.forEach(position => {
		var latlng = new google.maps.LatLng(position.lat, position.lng);

		// create marker\
        var iconBase = '/wp-content/themes/dfhrealestate/assets/images/'
		var marker = new google.maps.Marker({
			position	: latlng,
			map			: map,
			icon        : iconBase + 'dfh-home-map-marker.png'
			//htmlContent : $marker.html()
		});

		// var contentString = `
		// 	<a href="${position.url}" class="map-links">
		// 	<div>
		// 		<img src="${position.image}" class="map-thumb" />
		// 		<h4 class="entry-title">${position.title}</h4>
		// 		<p style="font-size:1.5rem;margin-bottom:0.5rem;">$${position.price}</p>
		// 		<div class="listing-features">
		// 			<div class="listing-feature-sm">${position.sqft} sqft <i class="fa fa-home"></i></div>
		// 			<div class="listing-feature-sm">${position.bed} <i class="fa fa-bed"></i></div>
		// 			<div class="listing-feature-sm">${position.bath} <i class="fa fa-bath"></i></div>
		// 		</div>
		// 	</div>
		// 	</a>
		// `;

		// create info window
		// var infowindow = new google.maps.InfoWindow({
		// 	maxWidth: 286,
		// 	content: '<div style="height:296px;width:274px;text-align:center;line-height:296px;""><img src="/wp-content/themes/cowbayrealestate/images/ajax-loader.gif"></div>'
		// });

		// add to array
		map.markers.push( marker );

        // show info window when marker is clicked & close other markers
		// google.maps.event.addListener(marker, 'click', function() {
		// 	infowindow.open(map, marker);
		// 	//$.ajax({
		// 		//success:function() {
		// 			infowindow.setContent(contentString);
		// 		//}
		// 	//});
		// });

		// close info window when map is clicked
		// google.maps.event.addListener(map, 'click', function(event) {
		// 	if (infowindow) {
		// 		infowindow.close();
		// 	}
		// });
	});

	// add markers
	// $markers.each(function(){

 //    	add_marker( $(this), map );

	// });

	// center map
	center_map( map );

	// Add a marker clusterer to manage the markers.
    //var markerCluster = new MarkerClusterer(map, map.markers,
    	//{imagePath: 'https://cowichanrealestate.ca/wp-content/themes/cowbayrealestate/js/gmaps/m'});

	// return
	return map;

}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

//function add_marker( $marker, map ) {



	// add to array
	//map.markers.push( marker );



	// show info window when marker is clicked & close other markers
	// google.maps.event.addListener(marker, 'click', function() {
	// 	infowindow.open(map, marker);
	// });

	// // close info window when map is clicked
	// google.maps.event.addListener(map, 'click', function(event) {
	// 	if (infowindow) {
	// 		infowindow.close();
	// 	}
	// });
//}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length == 1 )
	{
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else
	{
		// fit to bounds
		map.setCenter( bounds.getCenter() );
	   	map.setZoom( 9 ); // Change the zoom value as required
		//map.fitBounds( bounds ); // This is the default setting which I have uncommented to stop the World Map being repeated

	}

}

/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type	function
*  @date	8/11/2013
*  @since	5.0.0
*
*  @param	n/a
*  @return	n/a
*/
// global var
var map = null;

$(document).ready(function() {
	//$(window).scroll(function() {
		//if ( $(window).scrollTop() + $(window).height() >= $('.acf-map').offset().top ) {
			$('.acf-map').each(function() {
				//if(!$('.acf-map').attr('loaded')) {
					map = new_map( $(this) );
					//$('.acf-map').attr('loaded', true);
				//}
			});
		//}
	//});
});

// var mapDiv = document.getElementsByClassName( 'acf-map' );
// var scrollPosition = 1000;

// function mapFunction() {
// 	if ( window.pageYOffset > scrollPosition ) {
//   		map = new_map( $(mapDiv) );
// 	}
// }

// window.onload = mapFunction;

})(jQuery);
