import { MarkerClusterer } from '@googlemaps/markerclusterer';

document.addEventListener('alpine:init', () => {
    let map
    let markers = []
    let markerClusters
    let url
    var zoomListener
    var dragListener
    Alpine.data('listingsData', () => ({
        listType: '',
        listings: null,
        listingsHeight: 0,
        mapListings: null,
        pagination: {
            total: 0,
            lastPage: 0,
            perPage: 0,
            currentPage: 1,
            from: 0,
            to: 0
        },
        filter: {
            city: '',
            minPrice: 0,
            maxPrice: 0,
            minBath: 0,
            minBed: 0,
            propertyType: '',
            buildingType: '',
            buildingSize: 0,
            landSize: 0,
            listedDate: '',
        },
        mapCenter: {
            lat: 48.50053843579536,
            lng: -123.41990614105775
        },
        mapZoom: 11,
        mapBounds: {},
        transitioning: false,
        initListings(listType, perPage) {
            this.setListingsHeight()
            this.listType = listType
            this.pagination.perPage = perPage
            this.transitioning = true
            this.initMap()
            //this.getParameters()
            //this.setMapBounds()
            //this.getMapListings()
            //this.getListings()
            window.addEventListener("popstate", (e) => {
                //this.removeMapListeners()
                this.getParameters()
                this.getMapListings()
                //this.addMapListeners()
                // this.getListings()
            })
        },
        setListingsHeight() {
            this.listingsHeight = window.innerHeight - document.getElementById('hybridListings').offsetTop
        },
        getParameters() {
            var urlParams = new URLSearchParams(location.search)
            this.pagination.currentPage = urlParams.has('currentPage') ? parseInt(urlParams.get('currentPage')) : 1
            if (urlParams.has('mapCenter')) {
                let center = urlParams.get('mapCenter').split(',')
                this.mapCenter.lat = Number(center[0])
                this.mapCenter.lng = Number(center[1])
            }
            if (urlParams.has('mapZoom')) {
                this.mapZoom = parseInt(urlParams.get('mapZoom'))
            }
            if (urlParams.has('mapBounds')) {
                let bounds = urlParams.get('mapBounds').split(',')
                this.mapBounds.south = Number(bounds[0])
                this.mapBounds.west = Number(bounds[1])
                this.mapBounds.north = Number(bounds[2])
                this.mapBounds.east = Number(bounds[3])
            } else {
                //map.fitBounds(fitMarkers)
                this.getMapBounds()
            }
            this.filter.city = urlParams.has('city') ? urlParams.get('city') : ''
            this.filter.minBath = urlParams.has('minBath') ? urlParams.get('minBath') : 0
            this.filter.minBed = urlParams.has('minBed') ? urlParams.get('minBed') : 0
            this.filter.minPrice = urlParams.has('minPrice') ? urlParams.get('minPrice') : 0
            this.filter.maxPrice = urlParams.has('maxPrice') ? urlParams.get('maxPrice') : 0
            this.filter.propertyType = urlParams.has('propertyType') ? urlParams.get('propertyType') : ''
            this.filter.buildingType = urlParams.has('buildingType') ? urlParams.get('buildingType') : ''
            this.filter.buildingSize = urlParams.has('buildingSize') ? urlParams.get('buildingSize') : 0
            this.filter.landSize = urlParams.has('landSize') ? urlParams.get('landSize') : 0
            this.filter.listedDate = urlParams.has('listedDate') ? urlParams.get('listedDate') : ''
        },
        initMap() {
            map = new google.maps.Map(document.querySelector('#mlsMap'), {
                center: this.mapCenter,
                zoom: this.mapZoom,
                gestureHandling: 'greedy',
                fullscreenControl: false,
            })
            google.maps.event.addListenerOnce(map, 'idle', () => {
                //console.log('map idle...')
                //this.setMapBounds()
                //this.getListings()
            })
            //this.getParameters()
            //this.addMapListeners()
            this.getMapListings()
        },
        addMapListeners() {
            zoomListener = map.addListener('zoom_changed', () => {
                window.setTimeout(() => {
                    this.getMapBounds()
                    this.setMapParameters()
                    this.resetPagination()
                    this.getListings()
                    console.log('map zoom...')
                }, 500)
            });
            dragListener = map.addListener('dragend', () => {
                window.setTimeout(() => {
                    this.getMapBounds()
                    this.setMapParameters()
                    this.resetPagination()
                    this.getListings()
                    console.log('map dragend...')
                }, 500)
            });
        },
        getListings() {
            var listingParams = new URLSearchParams({
                action: "fetch_listings_ajax",
                nonce: realEstate_ajax.nonce,
                listType: this.listType,
                perPage: this.pagination.perPage,
                currentPage: this.pagination.currentPage,
                mapBounds: this.mapBounds.south + ',' + this.mapBounds.west + ',' + this.mapBounds.north + ',' + this.mapBounds.east,
                city: this.filter.city,
                minBath: this.filter.minBath,
                minBed: this.filter.minBed,
                minPrice: this.filter.minPrice,
                maxPrice: this.filter.maxPrice,
                propertyType: this.filter.propertyType,
                buildingType: this.filter.buildingType,
                buildingSize: this.filter.buildingSize,
                landSize: this.filter.landSize,
                listedDate: this.filter.listedDate,
            })
            //console.log(listingParams.toString())
            this.transitioning = true
            fetch(realEstate_ajax.ajax_url, {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: listingParams
            })
            .then(response => response.json())
            .then(data => {
                //console.log(data)
                this.listings = data
                this.pagination.total = this.listings['@odata.count']
                this.pagination.lastPage = Math.ceil(this.pagination.total / this.pagination.perPage)
                this.pagination.from = (this.pagination.currentPage * this.pagination.perPage) - (this.pagination.perPage - 1)
                let to = this.pagination.currentPage * this.pagination.perPage
                if (to >= this.pagination.total) {
                    to = this.pagination.total
                }
                this.pagination.to = to
                this.transitioning = false
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            //console.log(this.pagination.currentPage, this.pagination.from, this.pagination.to, this.pagination.total)
        },
        getMapListings() {
            var listingParams = new URLSearchParams({
                action: "fetch_listings_map",
                nonce: realEstate_ajax.nonce,
                listType: this.listType,
                city: this.filter.city,
                minBath: this.filter.minBath,
                minBed: this.filter.minBed,
                minPrice: this.filter.minPrice,
                maxPrice: this.filter.maxPrice,
                propertyType: this.filter.propertyType,
                buildingType: this.filter.buildingType,
                buildingSize: this.filter.buildingSize,
                landSize: this.filter.landSize,
                listedDate: this.filter.listedDate,
            })
            fetch(realEstate_ajax.ajax_url, {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: listingParams
            })
                .then(response => response.json())
                .then(data => this.mapListings = data )
                .then(() => this.deleteMarkers())
                .then(() => this.removeMapListeners())
                //.then(() => this.getMapBounds())
                .then(() => this.getParameters())
                .then(() => this.positionMap())
                .then(() => this.setMapMarkers())
                .then(() => this.getListings())
                .then(() => this.addMapListeners())
        },
        removeMapListeners() {
            google.maps.event.removeListener(zoomListener)
            google.maps.event.removeListener(dragListener)
        },
        getMapBounds() {
            let center = map.getCenter()
            let zoom = map.getZoom()
            let bounds = map.getBounds()
            this.mapCenter = center.toJSON()
            this.mapZoom = zoom
            this.mapBounds = bounds.toJSON()
            //console.log(bounds.toString())
            //map.fitBounds(bounds)
        },
        setMapParameters() {
            url = new URL(window.location.href)
            url.searchParams.set('mapCenter', this.mapCenter.lat + ',' + this.mapCenter.lng)
            url.searchParams.set('mapZoom', this.mapZoom)
            url.searchParams.set('mapBounds', this.mapBounds.south + ',' + this.mapBounds.west + ',' + this.mapBounds.north + ',' + this.mapBounds.east)
            history.pushState(null, document.title, url)
            console.log('bounds url: ' + url)
        },
        positionMap() {
            //map.fitBounds(this.mapBounds)
            map.setCenter(this.mapCenter)
            map.setZoom(this.mapZoom)
        },
        changePage(newPage) {
            this.pagination.currentPage = newPage
            url = new URL(window.location.href);
            url.searchParams.set('currentPage', newPage);
            history.pushState(null, document.title, url.toString());
            console.log('pageChange url: ' + url)
            document.querySelector('#propertyListings').scrollTo({ top: 0, behavior: 'smooth' })
            window.scrollTo({ top: 0, behavior: 'smooth' })
            this.getListings()
        },
        resetPagination() {
            this.pagination.currentPage = 1
            url = new URL(window.location.href);
            url.searchParams.delete('currentPage');
        },
        filterPage(filterType, filterValue) {
            //console.log(filterValue)
            this.pagination.currentPage = 1
            url = new URL(window.location.href);
            url.searchParams.delete('currentPage');
            url.searchParams.set(filterType, filterValue);
            history.pushState(null, document.title, url.toString());
            console.log('filter url: ' + url)
            //this.deleteMarkers()
            this.getMapListings()
            //this.getListings()
        },
        resetFilters() {
            this.filter.city = ''
            this.filter.minPrice = 0
            this.filter.maxPrice = 0
            this.filter.minBath = 0
            this.filter.minBed = 0
            this.filter.propertyType = ''
            this.filter.buildingType = ''
            this.filter.buildingSize = 0
            this.filter.landSize = 0
            this.filter.listedDate = ''
            url = new URL(window.location.href);
            url.searchParams.delete('city');
            url.searchParams.delete('minPrice');
            url.searchParams.delete('maxPrice');
            url.searchParams.delete('minBath');
            url.searchParams.delete('minBed');
            url.searchParams.delete('propertyType');
            url.searchParams.delete('buildingType');
            url.searchParams.delete('buildingSize');
            url.searchParams.delete('landSize');
            url.searchParams.delete('listedDate');
            history.pushState(null, document.title, url.toString());
            this.resetPagination()
            this.getMapListings()
        },
        setMapMarkers() {
            //const fitMarkers = new google.maps.LatLngBounds();
            for (let i = 0; i < this.mapListings.value.length; i++) {
                const coords = this.mapListings.value[i].Coordinates;

                if (coords) {
                    //console.log(coords);
                    const latlng = new google.maps.LatLng(coords[1], coords[0]);
                    // create marker
                    const iconBase = '/wp-content/themes/dfhrealestate/assets/images/'
                    const marker = new google.maps.Marker({
                        position: latlng,
                        map: map,
                        icon: iconBase + 'dfh-home-map-marker.png'
                        //htmlContent : $marker.html()
                    })
                    // fitMarkers.extend({
                    //     lat: marker.position.lat(),
                    //     lng: marker.position.lng()
                    // })
                    // map.fitBounds(fitMarkers)

                    // add to array
                    markers.push(marker)
                }
            }
            markerClusters = new MarkerClusterer({ markers, map });
        },
        deleteMarkers() {
            if (markers.length > 0) {
                for (let i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }
                markerClusters.setMap(null);
                markers = [];
            }
        }
    }))
})